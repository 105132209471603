@import "~antd/dist/antd.css";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

@font-face {
  font-family: "baseFont";
  src: url("./fonts/Circular\ Air\ Pro-Book.otf");
}

@font-face {
  font-family: "boldFont";
  src: url("./fonts/Circular\ Air\ Pro-Bold.otf");
}

body {
  margin: 0;
  padding: 0;
  font-family: baseFont, sans-serif;
  overflow-x: hidden;
}

.slide-container {
  padding: 10px;
}

.header-search .ant-select-selection.ant-select-selection--single {
  outline: none !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  border: none !important;
  background: transparent;
}

.header-search .ant-select-selection__placeholder {
  text-align: center !important;
}

.transparent-header-search .ant-select-selection.ant-select-selection--single {
  color: white !important;
}

.transparent-header-search .ant-select-selection__placeholder {
  color: white !important;
}

.solid-header-search .ant-select-selection.ant-select-selection--single {
  color: black !important;
}

.solid-header-search .ant-select-selection__placeholder {
  color: black !important;
}

ul.ant-dropdown-menu {
  max-height: 400px;
  overflow-y: scroll;
}
